import React from "react"
import { Link } from "gatsby"
import Nav from "../Nav/Nav"
import { StaticQuery, graphql } from "gatsby"

import styles from "./topBar.module.css"

const TopBar = () => (
  <StaticQuery
    query={query}
    render={data => {
      const { title } = data.site.siteMetadata

      return (
        <div className={styles.topBar}>
          <div>
            <Link className={styles.left} to={"/"} alt={title}>
              <img
                src={data.logo.childImageSharp.fluid.src}
                alt={title}
                className={styles.logo}
              />
            </Link>
          </div>
          <div className={styles.right}>
            <Nav />
          </div>
        </div>
      )
    }}
  />
)

const query = graphql`
  query query {
    logo: file(absolutePath: { regex: "/logo.webp/" }) {
      childImageSharp {
        fluid (quality: 100){
          ...GatsbyImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default TopBar
