import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faRedditAlien,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';

library.add(
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faRedditAlien,
  faWhatsapp,
);
