import React from "react"
import PropTypes from "prop-types"
import { getPage } from "./headerUtils"
import { format } from "date-fns"

import styles from "./header.module.css"

const Header = ({ pathname, attrs }) => {
  const currentPage = getPage(pathname)

  switch (currentPage) {
    case "home":
      return (
        <header className={styles.header}>
          <h1 className={styles.subtitle}>
            Premium, high quality and ready to use{" "}
            <span className={styles.colored}>themes for Keycloak.</span> <br />
          </h1>
          <p className={styles.description}>
            Save time and improve the look and feel of your Keycloak <br />
            installation with our manually crafted themes. <br />
          </p>
        </header>
      )

    case "faq":
      return (
        <header className={styles.header}>
          <h1 className={styles.subtitle}>Frequently asked questions</h1>
          <p className={styles.description}>
            Questions about our themes? <br />
            On this page you will probably find your answer.
          </p>
        </header>
      )

    case "tags":
      return (
        <header className={styles.header}>
          <h1 className={styles.subtitle}>All topics</h1>
          <p className={styles.description}>Search for articles by topics.</p>
        </header>
      )

    case "tagsDetails":
      return (
        <header className={styles.header}>
          <h1 className={styles.subtitle}>
            Posts about the topic{" "}
            <span className={styles.primary}>{attrs.tag}</span>
          </h1>
        </header>
      )

    case "blog":
      return (
        <header className={styles.header}>
          <h1 className={styles.subtitle}>Blog</h1>
        </header>
      )

    case "theme":
      return (
        <header className={styles.header}>
          <h1 className={styles.subtitle}>{attrs.title}</h1>
          <p className={styles.description}>{attrs.description}</p>
        </header>
      )

    case "contact":
      return (
        <header className={styles.header}>
          <h1 className={styles.subtitle}>Contact</h1>
          <p className={styles.description}>
            Fill the following form to send us a message. <br />
            We will reply as soon as possible.
          </p>
        </header>
      )

    case "post":
      const isoDate = attrs.post.frontmatter.date
      const date = format(new Date(isoDate), "MMMM d, yyyy")

      return (
        <header className={styles.header}>
          <h1 className={styles.subtitle}>{attrs.post.frontmatter.title}</h1>
          <p className={styles.description}>
            <time className={styles.postDate} dateTime={isoDate}>
              {date}
            </time>
          </p>
        </header>
      )

    case "404":
      return (
        <header className={styles.header}>
          <h1 className={styles.subtitle}>Not found</h1>
          <p className={styles.description}>
            What you are looking for cannot be found here :-(.
          </p>
        </header>
      )

    default:
      return <span />
  }
}

Header.propTypes = {
  pathname: PropTypes.string,
  attrs: PropTypes.object,
}

export default Header
