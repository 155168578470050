import { startsWithPrefix } from "ramda-extension"

export const getPage = path => {
  const otherPages = {
    "/": "home",
    "/faq": "faq",
    "/faq/": "faq",
    "/tags": "tags",
    "/tags/": "tags",
    "/blog": "blog",
    "/blog/": "blog",
    "/contact": "contact",
    "/contact/": "contact",
    "/404": "404",
    "/404/": "404",
    "/404.html": "404",
  }

  if (otherPages[path]) return otherPages[path]

  if (startsWithPrefix("/tags/", path)) {
    return "tagsDetails"
  }

  if (startsWithPrefix("/themes/", path)) {
    return "theme"
  }

  return "post"
}
