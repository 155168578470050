import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"
import { format } from "date-fns"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faRss } from "@fortawesome/free-solid-svg-icons"

import styles from "./footer.module.css"

const Footer = ({ title }) => (
  <StaticQuery
    query={footerQuery}
    render={data => {
      const {
        title,
        social: { twitterUrl },
      } = data.site.siteMetadata

      return (
        <footer className={styles.footer}>
          <div className={styles.wrapper}>
            © {format(new Date(), "yyyy")}{" "}
            <Link className={styles.link} title={title} to="/">
              KeycloakThemes
            </Link>
            {` - `}
            <a
              href="https://www.iubenda.com/privacy-policy/18890177"
              target="__blank"
              title="Privacy Policy "
              rel="noreferrer noopener"
            >
              Privacy Policy
            </a>
            {` - `}
            <a
              href="https://www.iubenda.com/privacy-policy/18890177/cookie-policy"
              target="__blank"
              title="Cookie Policy"
              rel="noreferrer noopener"
            >
              Cookie policy
            </a>
          </div>
          <div className={styles.social}>
            <a
              title="Twitter"
              target="_blank"
              href={twitterUrl}
              rel="noreferrer noopener"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a
              title="RSS Feed"
              target="_blank"
              href={`https://keycloakthemes.com/rss.xml`}
              rel="noreferrer noopener"
            >
              <FontAwesomeIcon icon={faRss} />
            </a>
          </div>
        </footer>
      )
    }}
  />
)

Footer.propTypes = {
  title: PropTypes.string,
}

const footerQuery = graphql`
  query footer {
    site {
      siteMetadata {
        title
        social {
          twitterUrl
        }
      }
    }
  }
`
export default Footer
