import React, { useState } from "react"
import { Link } from "gatsby"
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'

import styles from "./nav.module.css"

const Nav = () => {
  const [display, setDisplay] = useState(false)

  return (
    <>
      <div
        className={`${styles.toggle} ${display ? styles.toggleActive : ""}`}
        onClick={() => setDisplay(!display)}
        role="button"
        tabIndex="0"
        onKeyPress={() => setDisplay(!display)}
      >
        <span className={styles.hTop}></span>
        <span className={styles.hMiddle}></span>
        <span className={styles.hBottom}></span>
      </div>

      <div className={`${styles.overlay} ${display ? styles.menuOpen : ""}`}>
        <nav className={styles.overlayMenu}>
          <ul>
            <li>
              <Link to={`/`} title="Home">
                <span>Home</span>
              </Link>
            </li>
            <li>
              <Link to={`/blog`} title="Blog">
                <span>Blog</span>
              </Link>
            </li>
            <li>
              <Link to={`/faq`} title="Faq">
                <span>Faq</span>
              </Link>
            </li>
            <li>
              <Link to={`/tags`} title="All topics">
                <span>All topics</span>
              </Link>
            </li>
            <li>
              <Link to={`/contact`} title="Contact">
                <span>Contact</span>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </>
  )
}

export default Nav
