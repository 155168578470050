import React from "react"
import TopBar from "../TopBar/TopBar"
import Header from "../Header/Header"
import Footer from "../Footer/Footer"

// Icons
import "../../fontawesome"

import styles from "./layout.module.css"

const Layout = ({ children, title, location, headerAttrs, size }) => {
  // eslint-disable-next-line no-unused-vars
  return (
    <>
      <div className={styles.topBar}>
        <TopBar />
      </div>

      <div className={styles.header}>
        <Header
          title={title}
          pathname={location.pathname || location}
          attrs={headerAttrs}
        />
      </div>

      <div className={size === "lg" ? styles.mainLg : styles.main}>
        <div className={styles.content}>{children}</div>
      </div>

      <Footer title={title} />
    </>
  )
}

export default Layout
